import React from "react";
import ContentWrapper from "../../Common/ContentWrapper";

const ChannelRules = () => {
  return (
    <ContentWrapper>
      <h2>ChannelRules Page</h2>
    </ContentWrapper>
  );
};

export default ChannelRules;
