import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";





const NewOpsDashboard = () => {

  const [agentListCall, setAgentListCall] = useState(true);
  const [url, setUrl] = useState(null);


   let username = localStorage.getItem(
     "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
   );
   let id_token = localStorage.getItem(
     "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
       username +
       ".idToken"
   );
   async function fetchList(dashboard_id) {
    const request = await axios.get(
      config.regulatorPayableOpsDashbard+dashboard_id,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    return request.data.EmbedUrl
   
  }
  useEffect(async () => {
    if (agentListCall === true) {
      // setLoad(true);
      setAgentListCall(false);
     
     let response= await fetchList("28863df3-89a8-4030-a38d-e5e4aa6e9e19");
     setUrl(response);
    }
  }, [agentListCall]);



  return (
    <ContentWrapper>
      <span className="pageheading">OPS Dashboard </span>
      <br />
      <br />
      <iframe
        src={url}
        height="800px"
        width="100%"
        style={{ marginTop: "20px" }}
      />
    </ContentWrapper>
  );
};

export default NewOpsDashboard;
