import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";

import { useSelector, useDispatch } from "react-redux";
import { setChannels } from '../../../../redux/actions/productActions';

import MaterialTable ,{ MTableToolbar }from 'material-table';
import { ExportToCsv } from "export-to-csv";



const HouseHoldReport = () => {


  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now
    .getDate()
    .toString()
    .padStart(2, "0");
  const currentDate = `${now.getFullYear()}-${month}-${day}`;

  const [load, setLoad] = useState(true);
  const [agentList, setAgentList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [agentListDownloadCall, setAgentListDownloadCall] = useState(false);

  const [selectedChannel, setSelectedChannel] = useState("All");
  const pageNo = 1
  const sizePerPage= 10
 

  const [empId, setEmpId] = useState(true);
  const [empList, setEmpList] = useState([]);
  const [empClick, setEmpClick] = useState(false);
  const [empLoad, setEmpLoad] = useState(false);
  const pii= JSON.parse(localStorage.getItem('PII'))
  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
    username +
    ".idToken"
  );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const  fetchList=async (page_no,page_size,search_text) =>{
    let query
    if(search_text==undefined){
     query = ""
    }else{
     query= search_text
    }
   const request= await axios.get(
      config.regulatorHouseHoldReport +
      "page=" +
      page_no +
      "&size=" +
      page_size +
      "&channel=" +
      selectedChannel+
      "&search_string="+
      query,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    setAgentList(request.data[0].payload);
    setLoad(false);
    const response={data:request.data[0].payload,total_count: request.data[0].meta_info[0].total_count}
    return response
  }

        async function downloadList() {
        const request = await axios.get(
          config.regulatorHouseHoldReport +
          "&channel=" +
          selectedChannel +
          "&as_of=" +
          currentDate,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          }
        );
        setAgentList(request.data[0].payload);
        csvExporter.generateCsv(request.data[0].payload);
      }

              async function fetchChannelList() {
          const request = await axios.get(config.channelList, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          });
          dispatch(setChannels(request.data));
          setChannelList(request.data);
        }
              async function fetchEmp() {
        const request = await axios.get(config.empDetails + empId, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        setEmpLoad(false);
        if (request.data !== null) {
          setEmpList(request.data);
        }
      }
  useEffect(() => {
    setChannelList(dataList.channels.channels);

    if (agentListCall === true) {
      setLoad(true);
      setAgentListCall(false);

      fetchList(pageNo,sizePerPage);
    }

    if (agentListDownloadCall === true) {
      setAgentListDownloadCall(false);

      downloadList();
    }

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {

        fetchChannelList();
      }
    }

    if (empClick === true) {
      setEmpClick(false);
      setEmpLoad(true);

      fetchEmp();
    }
  }, [agentListCall, agentListDownloadCall, agentList, channelList, empClick]);

  const channelSelect = e => {
    e.preventDefault();
    setSelectedChannel(e.target.value);
    setAgentListCall(true);
  };



  const dowloadfile = () => {
    setAgentListDownloadCall(true);
  }

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "household_report"
  };

  const empIdClick = id => {
    console.log(id)
    setEmpList([]);
    setEmpClick(true);
    setEmpId(id);
    document.getElementById("modalEmpBtn").click();
  };

  const GetActionEmpIdFormat = (rowData) => {
    return (
      <div>
        <label
          className="empName"
          size="sm"
          onClick={() => empIdClick(rowData.empi)}
        >
          {rowData.empi}
        </label>
      </div>
    );
  };
 const GetRedactFormat=(rowData)=>{
  return (<>
    {pii ? (<div style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>{rowData}</div>)
      : <div >{rowData}</div>}
  </>
  );
 }
  const csvExporter = new ExportToCsv(options);
  const columns = [
    {
      title: "EMPI",
      field: "empi",
      render: rowData => GetActionEmpIdFormat(rowData)
    },
    {
      title: "Member ID",
      field: "member_id"
    },
    {
      title: "Email",
      field: "email",
      render: rowData => GetRedactFormat(rowData.email)
    },
    {
      title: "Status",
      field: "member_status"
    },
    {
      title: "First Name",
      field: "first_name",
      render: rowData => GetRedactFormat(rowData.first_name)
    },
    {
      title: "Last Name",
      field: "last_name",
      render: rowData => GetRedactFormat(rowData.last_name)
    },

  ];

 

  return (
    <ContentWrapper>
      <span className="pageheading"> HouseHold Report</span>

      <button
        data-toggle="modal"
        id="modalEmpBtn"
        data-target="#modalEmp"
        style={{ display: "none" }}
      >
        Emp
      </button>

      <div
        className="modal fade"
        id="modalEmp"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogEmpWidth">
          <div className="modal-content">
            <div className="modalHeading">Member Info</div>
            <div className="modalContentDiv">
              {empLoad === true && <div className="loader" />}
              {empLoad === true && (
                <div style={{ textAlign: "center" }}>Loading Member Info</div>
              )}
              {empLoad === false && (
                <div className="empDiv">
                  <div className="empDataDiv">
                    <span className="empQus">Name :</span>{" "}
                    {pii == true ? (
                      <span
                        style={{
                          backgroundColor: "black",
                          color: "black",
                          cursor: "not-allowed"
                        }}
                      >
                        {empList.first_name}&nbsp; {empList.last_name}
                      </span>
                    ) : (
                      <span>
                        {empList.first_name}&nbsp; {empList.last_name}
                      </span>
                    )}
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Emp Id :</span>{" "}
                    <span>{empList.empi}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Gender :</span>{" "}
                    {pii == true ? (
                      <span
                        style={{
                          backgroundColor: "black",
                          color: "black",
                          cursor: "not-allowed"
                        }}
                      >
                        {empList.gender}
                      </span>
                    ) : (
                      <span>{empList.gender}</span>
                    )}
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Plan Id :</span>{" "}
                    <span>{empList.plan_id}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">State Code :</span>{" "}
                    <span>{empList.state_code}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Benefit Begin :</span>{" "}
                    <span>{empList.benefit_begin}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Benefit End :</span>{" "}
                    <span>{empList.benefit_end}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Paid Through Date:</span>{" "}
                    <span>{empList.paid_through_date}</span>
                  </div>
                  <hr />
                </div>
              )}
            </div>
            <div className="modalFooter">
              <button
                data-dismiss="modal"
                id="modalCloseBtn2"
                style={{ display: "block" }}
                className="modalFooterDoneBtn"
              >
                Done
              </button>
              <button
                data-dismiss="modal"
                id="modalCloseBtn1"
                style={{ display: "block" }}
                className="modalFooterCloseBtn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: props => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  <select
                    onChange={channelSelect}
                    className="filterByDateButton channelFilterPosition searchInput"
                    style={{ textAlign: "center" }}
                  >
                    <option value="All"> Select Channel </option>
                    {channelList.map((item, index) => (
                      <option key={index} value={item.channel_name}>
                        {item.channel_abbr}
                      </option>
                    ))}
                  </select>
                </div>
              )
            }}
            columns={columns}
            data={query =>
              new Promise((resolve, reject) => {
                console.log(query.search);
                fetchList(query.page + 1, query.pageSize, query.search).then(
                  result => {
                    resolve({
                      data: result.data, // your data array
                      page: query.page, // current page number
                      totalCount: result.total_count // total row number
                    });
                    reject();
                  }
                );
              })
            }
            detailPanel={rowData => {
              return (
                <div className="detailPanel">
                  <table className="detailPanelTable">
                    <tr className="detailPaneltr">
                      <th className="detailPanelth" scope="col">
                        Plan ID
                      </th>
                      <th className="detailPanelth" scope="col">
                        Monthly Share
                      </th>
                      <th className="detailPanelth" scope="col">
                        Dependent Count
                      </th>
                      <th className="detailPanelth" scope="col">
                        Admin Reserve
                      </th>
                      <th className="detailPanelth" scope="col">
                        Sharewallet
                      </th>
                      <th className="detailPanelth" scope="col">
                        Benefit Begin
                      </th>
                      <th className="detailPanelth" scope="col">
                        Benefit End
                      </th>
                      <th className="detailPanelth" scope="col">
                        Paid Through Date
                      </th>
                      <th className="detailPanelth" scope="col">
                        Total Revenue
                      </th>
                      <th className="detailPanelth" scope="col">
                        Expected Revenue
                      </th>
                    </tr>
                    <tr>
                      <td className="detailPaneltd">{rowData.plan_id}</td>
                      <td className="detailPaneltd">{rowData.monthly_share}</td>
                      <td className="detailPaneltd">
                        {rowData.dependent_count}
                      </td>
                      <td className="detailPaneltd">{rowData.admin_reserve}</td>
                      <td className="detailPaneltd">{rowData.sharewallet}</td>
                      <td className="detailPaneltd">{rowData.benefit_begin}</td>
                      <td className="detailPaneltd">{rowData.benefit_end}</td>
                      <td className="detailPaneltd">
                        {rowData.paid_through_date}
                      </td>
                      <td className="detailPaneltd">{rowData.total_revenue}</td>
                      <td className="detailPaneltd">
                        {rowData.expected_revenue}
                      </td>
                    </tr>
                  </table>
                </div>
              );
            }}
            options={{
              pageSize: 10,
              searchText: null,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "#ffffff",
                search: false
              }
            }}
            // title={false}
            actions={[
              {
                icon: "save_alt",
                tooltip: "Export Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#eb5757",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                     color: "#ffffff"
                  }
                },
                onClick: () => dowloadfile()
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                     color: "#ffffff"
                  }
                }
                // onClick: () => fetchList(pageNo,sizePerPage),
              }
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default HouseHoldReport;

