import React, { useState, useEffect } from 'react';
import ContentWrapper from "../../Common/ContentWrapper";
import axios from "../../../configuration/axios";
import apikey from '../../../configuration/apikey';
import config from '../../../configuration/config';
import styles from "../FileProcess/index.module.css";

import { useSelector, useDispatch } from "react-redux";
import { setChannels, setFileProcess } from "../../../redux/actions/productActions";

import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "bootstrap/dist/css/bootstrap.css";
import memberImg  from "../../../assets/images/upload_member_data.svg"
import transactionImg  from "../../../assets/images/upload_transactions.svg"
import needImg from "../../../assets/images/upload_need_requests.svg"

const FileProcess = () => {
  const [fiieList, setFileList] = useState(["null"]);
  const [load, setLoad] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [member, setMember] = useState(null);
  const [memberTemp, setMemberTemp] = useState(null);

  const [need, setNeed] = useState(null);
  const [needTemp, setNeedTemp] = useState(null);

  const [accumulator, setAccumulator] = useState(null);
  const [accumulatorTemp, setAccumulatorTemp] = useState(null);

  const [transaction, setTransaction] = useState(null);
  const [transactionTemp, setTransactionTemp] = useState(null);

  const [hash, setHash] = useState(null);
  const [transactionShow, setTransactionShow] = useState('');
  const [selectedChannel, setSelectedChannel] = useState("");
  const [resetPromoteVar, setResetPromote] = useState(null);
  const [agentListCall, setAgentListCall] = useState(true);


  let id_token = localStorage.getItem(config.id_tokenAdmin);
  
  const dataList = useSelector(state => state);
  const dispatch = useDispatch();
        async function fetchList() {
          const request = await axios.get(config.adminFileProcess, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          });
          if (dataList.channels.fileprocess.length === 0) {
            dispatch(setFileProcess(request.data));
          }
          setFileList(request.data);
          setLoad(false);
        }
                async function fetchChannelList() {
          const request = await axios.get(config.channelList, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          });
          dispatch(setChannels(request.data));
          setChannelList(request.data);
        }
              async function resetFn() {
       await axios.get(config.reset, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        setResetPromote(null);
      }

            async function promoteFn() {
            await axios.get(config.promote, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        setResetPromote(null);
      }
  useEffect(() => {
    setChannelList(dataList.channels.channels);
    if (agentListCall === true) {
      setAgentListCall(false);
      if (dataList.channels.fileprocess.length > 0 && fiieList[0] === "null") {
        setFileList(dataList.channels.fileprocess);
      } else {
        setLoad(true);

        fetchList();
      }
    }

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {

        fetchChannelList();
      }
    }

    if (hash !== null) {
      fetch(config.baseURL + config.removeHash + hash, {
        method: "GET",
        body: member,
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      })
        .then(response => response.json())
        .then(() => {
          setHash(null);
        })
        .catch(error => {
          console.error(error);
        });
      // async function removeFile() {
      //   const request = await axios.get(config.removeHash + hash, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-api-key": apikey.xapikey,
      //       token: id_token
      //     }
      //   });
      // }
      // removeFile();
    }

    if (
      member !== null
    ) {
      fetch(
        config.baseURL +
          config.memberUpload +
          "All" +
          "&source_file_name=" +
          member.name,
        {
          method: "POST",
          body: member,
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        }
      )
        .then(response => response.json())
        .then(() => {
          setMember(null);
          setMemberTemp(null);
        })
        .catch(error => {
          console.error(error);
        });
      // async function uploadMember() {
      //   const request = await axios.post(
      //     config.memberUpload + "All" + "&source_file_name=" + member.name,
      //     {
      //       body: member,
      //       method: "POST",
      //       headers: {
      //         "Content-Type":
      //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //         "x-api-key": "lIIwjqkmZF3V5T2Mk8qYG3aUStltuWQAaPkpq9JL",
      //         token: id_token
      //       }
      //     }
      //   );
      //   setMember(null);

      // }
      // uploadMember();
    }

    if (
      need !== null
    ) {
      fetch(
        config.baseURL +
          config.needUpload +
          "All" +
          "&source_file_name=" +
          need.name,
        {
          method: "POST",
          body: need,
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        }
      )
        .then(response => response.json())
        .then(() => {
          setNeed(null);
          setNeedTemp(null);
        })
        .catch(error => {
          console.error(error);
        });
      // async function uploadNeed() {
      //   const request = await axios.post(
      //     config.needUpload +
      //       selectedChannel +
      //       "&source_file_name=" +
      //       need.name,
      //     {
      //       headers: {
      //         "Content-Type":
      //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //         "x-api-key": apikey.xapikey,
      //         token: id_token
      //       }
      //     }
      //   );
      //   setNeed(null);
      // }
      // uploadNeed();
    }

    if (
      accumulator !== null
    ) {
      fetch(
        config.baseURL +
          config.accumulatorUpload +
          "All" +
          "&source_file_name=" +
          accumulator.name,
        {
          method: "POST",
          body: accumulator,
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        }
      )
        .then(response => response.json())
        .then(() => {
          setAccumulator(null);
          setAccumulatorTemp(null);
        })
        .catch(error => {
          console.error(error);
        });
      // async function uploadAccumulator() {
      //   const request = await axios.post(
      //     config.accumulatorUpload +
      //       selectedChannel +
      //       "&source_file_name=" +
      //       accumulator.name,
      //     {
      //       headers: {
      //         "Content-Type":
      //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //         "x-api-key": apikey.xapikey,
      //         token: id_token
      //       }
      //     }
      //   );
      //   setAccumulator(null);

      // }
      // uploadAccumulator();
    }

    if (
      transaction !== null 
    ) {
      fetch(
        config.baseURL +
          config.transactionUpload +
          selectedChannel +
          "&source_file_name=" +
          transaction.name,
        {
          method: "POST",
          body: transaction,
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        }
      )
        .then(response => response.json())
        .then(() => {
          setTransaction(null);
          setTransactionTemp(null);
        })
        .catch(error => {
          console.error(error);
        });
     
    }

    if (resetPromoteVar === "reset") {

      resetFn();
    }

    if (resetPromoteVar === "promote") {

      promoteFn();
    }
  }, [
    hash,
    member,
    need,
    accumulator,
    transaction,
    selectedChannel,
    resetPromoteVar,
    id_token
  ]);


  const channelSelect = e => {
    e.preventDefault();
    if (e.target.value === 'select') {
        setTransactionShow('')
    }
    else if (
            e.target.value !== "" ||
            e.target.value !== undefined ||
            e.target.value !== null
          ) {
            setTransactionShow(1);
          } else {
            setTransactionShow("");
          }
    setSelectedChannel(e.target.value); 
    setAgentListCall(true);
      
  }

  const memberData = event => {
    setMemberTemp(event.target.files[0]);

    setNeed(null);
    setAccumulator(null);
    setTransaction(null);
  };

  const uploadMember = () => {
    setMember(memberTemp);
  };

  const needData = event => {
    setNeedTemp(event.target.files[0]);

    setMember(null);
    setAccumulator(null);
    setTransaction(null);
  };

  const uploadNeed = () => {
    setNeed(needTemp);
  };
  
  const accumulatorData = event => {
    setAccumulatorTemp(event.target.files[0]);

    setMember(null);
    setNeed(null);
    setTransaction(null);
  };

  const uploadAccumulator = () => {
    setAccumulator(accumulatorTemp);
  };
  
  const transactionData = event => {
    setTransactionTemp(event.target.files[0]);

    setMember(null);
    setNeed(null);
    setAccumulator(null);
  };

  const uploadTransaction = () => {
    setTransaction(transactionTemp);
  };
  
  
  const reset = () => {
    setResetPromote("reset");
  };

  const promote = () => {
    setResetPromote("promot");
  };


  const GetActionFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn"
          size="sm"
          onClick={() => handleShow(cell, row)}
        >
          Remove
        </button>
      </div>
    );
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "uploaded_date",
      text: "Date of Upload",
      headerStyle: { minWidth: "160px" }
    },
    {
      dataField: "source_file_name",
      text: "File Name"
    },
    {
      dataField: "channel",
      text: "Channel"
    },
    {
      dataField: "hash",
      text: "Hash"
    },
    {
      dataField: "log_file",
      text: "Log File"
    },
    {
      dataField: "log",
      text: "Log"
    },
    {
      dataField: "status",
      text: "Status"
    },
    {
      dataField: "",
      text: "Option",
      formatter: GetActionFormat
    }
  ];

  const handleShow = (cell, row) => {
        setHash(row.hash);    
  };
  
  return (
    <ContentWrapper>
      <button className="filterByDateButton resetBtnHome" onClick={reset}>
        Reset
      </button>
      <button className="filterByDateButton resetBtnHome" onClick={promote}>
        Promote
      </button>
      <select
        onChange={channelSelect}
        // ref={input => (channelMenu = input)}
        className="filterByDateButton filterHome"
        style={{ textAlign: "center" }}
      >
        <option value="select"> Select Channel </option>
        {/* <option value="cneo"> CNEO </option> */}
        {channelList.map((item, index) => (
          <option key={index} value={item.channel_name}>
            {item.channel_abbr}
          </option>
        ))}
      </select>

      <div style={{ marginTop: "60px" }}>
        <div className={styles.card}>
          <div className={styles.cardContainer}>
            <div className={styles.headLabel}>
              <img src={memberImg.default} className={styles.iconImg} alt="" />{" "}
              Upload Member Data
            </div>
          </div>
          <div className={styles.uploadDiv}>
            <input type="file" id="file1" onChange={memberData} />
            <label htmlFor="file1">
              <span>Browse Member assets</span>
            </label>
            <br />
            {!memberTemp && <div className={styles.filename}>&nbsp;</div>}
            {memberTemp && (
              <div className={styles.filename}>{memberTemp.name}</div>
            )}
            <br />
            {/* {this.state.loader && <div className={styles.loader} />}
            {this.state.loader && (
              <div style={{ textAlign: "center" }}>Processing</div>
            )} */}
            {/* {!this.state.loader && ( */}
            <button className={styles.uploadBtn} onClick={uploadMember}>
              Upload data
            </button>
            {/* )} */}
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContainer}>
            <div className={styles.headLabel}>
              <img src={needImg.default} className={styles.iconImg} alt="" />{" "}
              Upload Need Requests
            </div>
          </div>
          <div className={styles.uploadDiv}>
            <input type="file" id="file3" onChange={needData} />
            <label htmlFor="file3">
              <span>Browse Needs data</span>
            </label>
            <br />
            {!needTemp && <div className={styles.filename}>&nbsp;</div>}
            {needTemp && <div className={styles.filename}>{needTemp.name}</div>}
            <br />
            {/* {this.state.loader2 && <div className={styles.loader} />}
            {this.state.loader2 && (
              <div style={{ textAlign: "center" }}>Processing</div>
            )} */}
            {/* {!this.state.loader2 && ( */}
            <button className={styles.uploadBtn} onClick={uploadNeed}>
              Upload data
            </button>
            {/* )} */}
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardContainer}>
            <div className={styles.headLabel}>
              <img src={needImg.default} className={styles.iconImg} alt="" />{" "}
              Upload Accumulator Files
            </div>
          </div>
          <div className={styles.uploadDiv}>
            <input type="file" id="file4" onChange={accumulatorData} />
            <label htmlFor="file4">
              <span>Browse Accumulator Files </span>
            </label>
            <br />
            {!accumulatorTemp && <div className={styles.filename}>&nbsp;</div>}
            {accumulatorTemp && (
              <div className={styles.filename}>{accumulatorTemp.name}</div>
            )}
            <br />
            {/* {this.state.loader3 && <div className={styles.loader} />}
            {this.state.loader3 && (
              <div style={{ textAlign: "center" }}>Processing</div>
            )} */}
            {/* {!this.state.loader3 && ( */}
            <button className={styles.uploadBtn} onClick={uploadAccumulator}>
              Upload data
            </button>
            {/* )} */}
          </div>
        </div>

        {transactionShow && (
          <div className={styles.card}>
            <div className={styles.cardContainer}>
              <div className={styles.headLabel}>
                <img
                  src={transactionImg.default}
                  className={styles.iconImg}
                  alt=""
                />
                Upload Transactions
              </div>
            </div>
            <div className={styles.uploadDiv}>
              <input type="file" id="file2" onChange={transactionData} />
              <label htmlFor="file2">
                <span>Browse Transactions</span>
              </label>
              <br />
              {!transactionTemp && (
                <div className={styles.filename}>&nbsp;</div>
              )}
              {transactionTemp && (
                <div className={styles.filename}>{transactionTemp.name}</div>
              )}
              <br />
              {/* {this.state.loader1 && <div className={styles.loader} />}
              {this.state.loader1 && (
                <div style={{ textAlign: "center" }}>Processing</div>
              )} */}
              {/* {!this.state.loader1 && ( */}
              <button className={styles.uploadBtn} onClick={uploadTransaction}>
                Upload data
              </button>
              {/* )} */}
            </div>
          </div>
        )}
      </div>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}
        {/* {!this.state.dataloader && ( */}

        {load === false && (
          <div className="tableInnerDiv">
            <ToolkitProvider
              id="table-to-xls"
              // ref={n => (this.node = n)}
              keyField="hash"
              data={fiieList}
              columns={columns}
              // filter={ filterFactory() }
              // pagination={ paginationFactory() }
              // selectRow={ { mode: 'checkbox', clickToSelect: true } }
              // expandRow={ expandRow }
              search
              exportCSV
            >
              {props => (
                <div>
                  <ExportCSVButton {...props.csvProps}>
                    Export CSV
                  </ExportCSVButton>
                  <SearchBar {...props.searchProps} />
                  <br />
                  <br />
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory()}
                    // expandRow={expandRow}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        )}
      </div>
    </ContentWrapper>
  );
  
};

export default FileProcess;
