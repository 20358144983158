import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";

const OpsDashboard = () => {
  const [agentListCall, setAgentListCall] = useState(true);
  const [url, setUrl] = useState(null);

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
      username +
      ".idToken"
  );
  async function fetchList(dashboard_id) {
    const request = await axios.get(
      config.regulatorPayableOpsDashbard + dashboard_id,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    );
    return request.data.EmbedUrl;
  }
  useEffect(async () => {
    if (agentListCall === true) {
      // setLoad(true);
      setAgentListCall(false);

      //  let response= await fetchList("8b9d05cd-6a4a-413c-9eb9-adfd7fcc9b56");
      let response = await fetchList("bda36368-a08e-4027-a4a6-1a8cca963d5e");

      setUrl(response);
    }
  }, [agentListCall]);

  return (
    <ContentWrapper>
      <span className="pageheading">Enrollment Report </span>
      <br />
      <br />
      <iframe
        src={url}
        height="800px"
        width="100%"
        style={{ marginTop: "20px" }}
      />
    </ContentWrapper>
  );
};

export default OpsDashboard;
