import React, { useState, useEffect } from "react";
import ContentWrapper from "../../Common/ContentWrapper";

const Member = () => {
  const [showFullScreen, setShowFullScreen] = useState(false);

  useEffect(() => {
    const handleFullscreenListener = (event) => {
      let isFullScreen = JSON.parse(event.data).isFullScreen;
      console.log(event.data);
      console.log(isFullScreen);

      setShowFullScreen(isFullScreen);
    };

    window.addEventListener("message", handleFullscreenListener);

    return () => {
      window.removeEventListener("message", handleFullscreenListener);
    };
  }, []);

  return (
    <ContentWrapper>
      {/* <span className="pageheading">OPS Dashboard </span> */}
      <br />
      <br />
      <iframe
        src={"https://csr.universalhealthfellowship.org:8443/MembersTab"}
        height="800px"
        width="100%"
        className={showFullScreen ? "panel-fullscreen" : ""}
        style={{ marginTop: "20px" }}
      />
    </ContentWrapper>
  );
};

export default Member;
