import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import { setAgentCommission } from '../../../../redux/actions/productActions';
import MaterialTable ,{ MTableToolbar }from 'material-table';
import { ExportToCsv } from "export-to-csv";
import close from "../../../../assets/images/close.png";

const AgentCommission = () => {


  const [load, setLoad] = useState(false);
  const [transactionLoad, setTransactionLoad] = useState(false);

  const [agentList, setAgentList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);



  const [agentListCall, setAgentListCall] = useState(true);
  const [agentId, setAgentId] = useState(true);

  const [empkey, setEmpkey] = useState(true);

  const [transactionClick, setTransactionClick] = useState(false);

   const [anchorEl, setAnchorEl] = React.useState(null);
   const [anchorElExport, setAnchorElExport] = React.useState(null);
     const [downloadLoad, setDownloadLoad] = useState(false);

   const open = Boolean(anchorEl);
   const openExport = Boolean(anchorElExport);
    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();

  
  const pageNo = 1
  const sizePerPage = 10

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "Agent Commission"
  };
  const csvExporter = new ExportToCsv(options);
  const  fetchTransaction=async (page_no,page_size)=>{
    const request = await axios.get(
      config.regulatorAgenctTransaction + agentId+
      "&page=" +
      page_no +
      "&size=" +
      page_size, 
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    setTransactionLoad(false);
    setTransactionList(request.data);
    const response={data:request.data[0].payload,total_count: request.data[0].meta_info[0].total_count}
    return response
  }
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      config.regulatorAgentCommission,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    if (dataList.channels.agentCommission.length === 0) {
      dispatch(setAgentCommission(request.data));
    }
    setAgentList(request.data);
    setLoad(false);
  }
  async function downloadList() {
    setDownloadLoad(true);
    const request = await axios.get(
      config.regulatorAgentCommission,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
    setDownloadLoad(false);
  }

  async function downloadTransactionList() {
    const request = await axios.get(
      config.regulatorAgenctTransaction + agentId, 
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data[0].payload);
  }

  
  useEffect(() => {
    if (agentListCall === true) {
      setAgentListCall(false);

      if (dataList.channels.agentCommission.length > 0 && agentList[0] === 'null') {
        setAgentList(dataList.channels.agentCommission);
      } else {
        setLoad(true);
        setAgentListCall(false);

        fetchList();
      }
    }

    if (transactionClick === true) {
      setTransactionClick(false);
      setTransactionLoad(true);

      fetchTransaction(pageNo,sizePerPage);
    }


  }, [
      agentListCall,
      agentList,
      transactionClick,
      agentId
    ]);

  const GetNameFormat = (row) => {
    return (
      <div>
        {row.first_name} {row.last_name}
      </div>
    );
  };




  const handleClick = (event, agent_id) => {
    console.log(agent_id);
    setEmpkey(agent_id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElExport(null);   
  };

  const GetActionFormat = (cell) => {
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={e => handleClick(e, cell.agent_id)}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
    );
  };

  const columns = [
    {
      field: "agent_id",
      title: "Agent ID	"
    },
    {
      field: "agent_name",
      title: "Agent Name	"
    },
    {
      field: "channel_id",
      title: "Channel ID	"
    },
    {
      field: "channel_name",
      title: "Channel Name	"
    },
    {
      field: "total_commission",
      title: "Total Commission	"
    },
    {
      field: "",
      title: "Action",
      render: rowData => GetActionFormat(rowData)
    }
  ];

  const transactionColumns = [
    {
      field: "transaction_date",
      title: "Date",
     
    },
    {
      field: "",
      title: "Name",
      render:rowData => GetNameFormat(rowData),
     
    },
    {
      field: "source_transaction_id",
      title: "Transaction ID	"
    },
    {
      field: "member_id",
      title: "Member ID	"
    },
    {
      field: "payment_number",
      title: "Payment Number	"
    },
    {
      field: "type",
      title: "Type"
    },
    {
      field: "payment_method",
      title: "Payment Method	"
    },
    {
      field: "transaction_amount",
      title: "Transaction Amount	"
    },
    {
      field: "application_fee",
      title: "Application Fee	"
    },
    {
      field: "monthly_share",
      title: "Monthly Share	"
    },
    {
      field: "uhf_monthly_fee",
      title: "UHF Monthly Fee	"
    },
    {
      field: "third_party_fee",
      title: "Dental VPC	"
    },
    {
      field: "health_tools",
      title: "Health Tools"
    }
  ];


  const transaction = (empi) => {
    setAnchorEl(null);
    setTransactionList([]);
    setTransactionClick(true);
    setAgentId(empi);
    document.getElementById("modalTransBtn").click();
  };



  return (
    <ContentWrapper>
      <span className="pageheading">Agent Commission</span>

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        // onClick={handleClick}
        style={{ display: "none" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => transaction(empkey)}>
          <label>TRANSACTION</label>
        </MenuItem>
      </Menu>
      {/* <SaveAltIcon
      style={{ fontSize: '50px' }}
       onClick={(e) => handleExportClick(e)} className="exportImg"/>  */}

      <button
        // className={styles.addNewBtn}
        data-toggle="modal"
        id="modalTransBtn"
        data-target="#modalTransaction"
        style={{ display: "none" }}
      >
        Transaction2
      </button>

      <div
        className="modal fade"
        id="modalTransaction"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogWidth">
          <div className="modal-content modal-content-bg">
            <div className="modalHeading tableModalHeading">
              Transaction Data
            </div>
            <img
              src={close}
              alt=""
              className="modalCloseBtn"
              data-dismiss="modal"
              id="modalCloseBtn"
            />

            <div className="tableModalContentDiv">
              {transactionLoad === true && <div className="loader" />}
              {transactionLoad === true && (
                <div style={{ titleAlign: "center" }}>
                  Loading Transaction Data
                </div>
              )}
              {transactionLoad === false && (
                <div>
                  {transactionList.length > 0 ? (
                    <MaterialTable
                      // other props
                      components={{
                        Toolbar: props => (
                          <div style={{ backgroundColor: "#f6f6e6" }}>
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                      columns={transactionColumns}
                      data={query =>
                        new Promise((resolve, reject) => {
                          fetchTransaction(query.page + 1, query.pageSize).then(
                            result => {
                              resolve({
                                data: result.data, // your data array
                                page: query.page, // current page number
                                totalCount: result.total_count // total row number
                              });
                              reject();
                            }
                          );
                        })
                      }
                      options={{
                        pageSize: 10,
                        searchFieldAlignment: "left",
                        searchFieldStyle: {
                          backgroundColor: "#ffffff"
                        }
                      }}
                      // // title={false}
                      actions={[
                        {
                          icon: "save_alt",
                          tooltip: "Export Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#eb5757",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                               color: "#ffffff"
                            }
                          },
                          onClick: () => downloadTransactionList()
                        },
                        {
                          icon: "refresh",
                          tooltip: "Refresh Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#41b5c2",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                               color: "#ffffff"
                            }
                          },
                          onClick: () => fetchTransaction(pageNo, sizePerPage)
                        }
                      ]}
                    />
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ titleAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: props => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  {downloadLoad === true && <div className="loaderDwnld" />}
                  {downloadLoad === true && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        right: "50px"
                      }}
                    >
                      downloading
                    </div>
                  )}
                  <div style={{ display: "inline-block" }}></div>
                </div>
              )
            }}
            columns={columns}
            data={agentList}
            options={{
              pageSize: 10,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "#ffffff"
              }
            }}
            // // title={false}
            actions={[
              {
                icon: "save_alt",
                tooltip: "Export Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#eb5757",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                     color: "#ffffff"
                  }
                },
                onClick: () => downloadList()
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                     color: "#ffffff"
                  }
                },
                onClick: () => fetchList()
              }
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default AgentCommission;

