import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import { setChannels } from '../../../../redux/actions/productActions';

import close  from "../../../../assets/images/close.png";

const HouseHoldQuery = () => {

  const [load, setLoad] = useState(false);
  const [transactionLoad, setTransactionLoad] = useState(false);
  const [needLoad, setNeedLoad] = useState(false);
  const [contributionLoad, setContributionLoad] = useState(false);


  const [agentList, setAgentList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [needList, setNeedList] = useState([]);
  const [contributionList, setContributionList] = useState([]);

  const [selectedChannel, setSelectedChannel] = useState("All");


  const [agentListCall, setAgentListCall] = useState(true);
  const [empId, setEmpId] = useState(true);
  const pageNo= 1
  const sizePerPage = 10

  const [empkey, setEmpkey] = useState(true);

  const [transactionClick, setTransactionClick] = useState(false);
  const [needsClick, setNeedsClick] = useState(false);
  const [contributionClick, setContributionClick] = useState(false);

  const [empList, setEmpList] = useState([]);
  const [empClick, setEmpClick] = useState(false);
  const [empLoad, setEmpLoad] = useState(false);
  const [channelList, setChannelList] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const pii= JSON.parse(localStorage.getItem('PII'))
  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
      username +
      ".idToken"
  );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();

  async function fetchChannelList() {
    const request = await axios.get(config.channelList, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey.xapikey,
        token: id_token
      }
    });
    dispatch(setChannels(request.data));
    setChannelList(request.data);
  }


  async function fetchList(page_no,page_size,selectedChannel,search_text) {
    let query
    if(search_text==undefined){
     query = ""
    }else{
     query= search_text
    }
    const request = await axios.get(
      config.regulatorHouseHoldQuery +
      "page=" +
      page_no +
      "&size=" +
      page_size +
      "&channel=" +
      selectedChannel+
      "&search_string="+
      query,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    setAgentList(request.data[0].payload);
    setLoad(false);
    const response={data:request.data[0].payload,total_count: request.data[0].meta_info[0].total_count}
    return response
  }
    async function fetchTransaction() {
        const request = await axios.get(
          config.regulatorHouseHoldQueryTransaction + empId,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          }
        );
        setTransactionLoad(false);
        setTransactionList(request.data);
      }

       async function fetchNeed() {
        const request = await axios.get(
          config.regulatorHouseHoldQueryNeeds + empId,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          }
        );
        setNeedLoad(false);
        setNeedList(request.data);
      }

        async function fetchContribution() {
        const request = await axios.get(
          config.regulatorHouseHoldQueryContribution + empId,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          }
        );
        setContributionLoad(false);
        setContributionList(request.data);
      }

        async function fetchEmp() {
        const request = await axios.get(config.empDetails + empId, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apikey.xapikey,
            token: id_token
          }
        });
        setEmpLoad(false);
        if (request.data !== null) {
          setEmpList(request.data);
        }
      }
  useEffect(() => {
    if (agentListCall === true) {
      setAgentListCall(false);

      if (
        dataList.channels.householdQuery.length > 0 &&
        agentList[0] === "null"
      ) {
        setAgentList(dataList.channels.householdQuery);
      } else {
        setLoad(true);
        setAgentListCall(false);
        fetchList(pageNo,sizePerPage, selectedChannel);
      }
    }

    if (transactionClick === true) {
      setTransactionClick(false);
      setTransactionLoad(true);

      fetchTransaction();
    }

    if (needsClick === true) {
      setNeedsClick(false);
      setNeedLoad(true);
     
      fetchNeed();
    }

    if (contributionClick === true) {
      setContributionClick(false);
      setContributionLoad(true);
    
      fetchContribution();
    }

    if (empClick === true) {
      setEmpClick(false);
      setEmpLoad(true);
    
      fetchEmp();
    }

    setChannelList(dataList.channels.channels);

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {

        fetchChannelList();
      }
    }
  }, [
      agentListCall,
      agentList,
      channelList,
      transactionClick,
      needsClick,
      contributionClick,
      empId,
      empClick
    ]);

  const GetNameFormat = (row) => {
    return (<>
      {pii ? (<div style={{ backgroundColor: "black", color: "black", cursor: "not-allowed" }}>
        {row.first_name} {row.last_name}
      </div>) : (<div >
        {row.first_name} {row.last_name}
      </div>)}
    </>
    );

  };

  const empIdClick = id => {
    setEmpList([]);
    setEmpClick(true);
    setEmpId(id);
    document.getElementById("modalEmpBtn").click();
  };

  const GetActionEmpIdFormat = ( row) => {
    return (
      <div>
        <label
          className="empName"
          size="sm"
          onClick={() => empIdClick(row.empi)}
        >
          {row.empi}
        </label>
      </div>
    );
  };

  // const handleClick = (cell, row) => {
  //   console.log(row)
  // };

  const handleClick = (event, row) => {
    console.log(row.empi);
    setEmpkey(row.empi)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const GetActionFormat = (row) => {
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => handleClick(e,  row)}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
    );
  };

  const columns = [
    {
      field: "empi",
      title: "EMPI ID",
      render: row => GetActionEmpIdFormat(row)
    },
    {
      field: "member_id_source",
      title: "Member ID"
    },
    {
      field: " ",
      title: "Member Name",
      render: row => GetNameFormat(row)
    },
    {
      field: "date_of_birth",
      title: "DOB",
      render: row => {
        return (
          <>
            {pii ? (
              <div
                style={{
                  backgroundColor: "black",
                  color: "black",
                  cursor: "not-allowed"
                }}
              >
                {row.date_of_birth}
              </div>
            ) : (
              <div>{row.date_of_birth}</div>
            )}
          </>
        );
      }
    },
    {
      field: "email",
      title: "Email",
      render: row => {
        return (
          <>
            {pii ? (
              <div
                style={{
                  backgroundColor: "black",
                  color: "black",
                  cursor: "not-allowed"
                }}
              >
                {row.email}
              </div>
            ) : (
              <div>{row.email}</div>
            )}
          </>
        );
      }
    },
    {
      field: "phone_no",
      title: "Phone",
      render: row => {
        return (
          <>
            {pii ? (
              <div
                style={{
                  backgroundColor: "black",
                  color: "black",
                  cursor: "not-allowed"
                }}
              >
                {row.phone_no}
              </div>
            ) : (
              <div>{row.phone_no}</div>
            )}
          </>
        );
      }
    },
    {
      field: "plan_id",
      title: "Plan ID",
      render: row => {
        return (
          <>
            {pii ? (
              <div
                style={{
                  backgroundColor: "black",
                  color: "black",
                  cursor: "not-allowed"
                }}
              >
                {row.plan_id}
              </div>
            ) : (
              <div>{row.plan_id}</div>
            )}
          </>
        );
      }
    },
    {
      field: "months_since_enrollment",
      title: "Months Since Enrollment",
      render: row => {
        return (
          <>
            {pii ? (
              <div
                style={{
                  backgroundColor: "black",
                  color: "black",
                  cursor: "not-allowed"
                }}
              >
                {row.months_since_enrollment}
              </div>
            ) : (
              <div>{row.months_since_enrollment}</div>
            )}
          </>
        );
      }
    },
    {
      field: "monthly_contribution_amount",
      title: "Mnthly Contribution Amount",
      render: row => {
        return (
          <>
            {pii ? (
              <div
                style={{
                  backgroundColor: "black",
                  color: "black",
                  cursor: "not-allowed"
                }}
              >
                {row.monthly_contribution_amount}
              </div>
            ) : (
              <div>{row.monthly_contribution_amount}</div>
            )}
          </>
        );
      }
    },
    {
      field: "",
      title: "Action",
      render: cell => GetActionFormat(cell)
    }
  ];


  const transactionColumns = [
    {
      field: "transaction_date",
      title: "Date"
    },
    {
      field: "transaction_id",
      title: "Transaction ID	"
    },
    {
      field: "member_id",
      title: "Member ID	"
    },
    {
      field: "payment_number",
      title: "Payment Number	"
    },
    {
      field: "type",
      title: "Type"
    },
    {
      field: "payment_method",
      title: "Payment Method	"
    },
    {
      field: "transaction_amount",
      title: "Transaction Amount	"
    },
    {
      field: "application_fee",
      title: "Application Fee	"
    },
    {
      field: "monthly_share",
      title: "Monthly Share	"
    },
    {
      field: "uhf_monthly_fee",
      title: "UHF Monthly Fee	"
    },
    {
      field: "third_party_fee",
      title: "Dental VPC	"
    },
    {
      field: "health_tools",
      title: "Health Tools"
    }
  ];

  const needColumns = [
    {
      field: "bill_key",
      title: "Need ID	"
    },
    {
      field: "date_received",
      title: "Date Received"
    },
    {
      field: "date_paid",
      title: "Date Paid	"
    },
    {
      field: "charged_amount",
      title: "Charge Amount	"
    },
    {
      field: "status",
      title: "Claim Status"
    },
    {
      field: "consultation_fee",
      title: "Consultation Fee	"
    },
    {
      field: "ineligible_amount",
      title: "Ineligible Amount	"
    },
    {
      field: "paid_amount",
      title: "Paid Amount	"
    },
    {
      field: "paid_provider_name",
      title: "Paid Provider Name	"
    },
    {
      field: "repricing_amount",
      title: "Repricing Amount"
    },
    {
      field: "sharing_percentage",
      title: "Sharing Percentage"
    }
  ];



  const contributionColumns = [
    {
      field: "need_id",
      title: "Contributed Need	"
    },
    {
      field: "paid_at",
      title: "Contributed At	"
    },
    {
      field: "amount",
      title: "Contributed Amount"
    }
  ];

  const transaction = (empi) => {
    console.log(empi);
    setTransactionList([]);
    setTransactionClick(true);
    setEmpId(empi);
    document.getElementById("modalTransBtn").click();
  };

  const needs = (empi) => {
    console.log(empi);

    setNeedList([]);
    setNeedsClick(true);
    setEmpId(empi);
    document.getElementById("modalNeedBtn").click();
  };

  const contribution = (empi) => {
    console.log(empi);

    setContributionClick(true);
    setEmpId(empi);
    document.getElementById("modalContributionBtn").click();
  };


  const channelSelect = e => {
    e.preventDefault();
    setAgentListCall(true);
    setSelectedChannel(e.target.value);
    fetchList(pageNo, sizePerPage, e.target.value);
  };






  return (
    <ContentWrapper>
      <span className="pageheading"> HouseHold Query</span>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        style={{ display: "none" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <label onClick={() => transaction(empkey)}>Transaction</label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <label onClick={() => needs(empkey)}>Needs</label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <label onClick={() => contribution(empkey)}>Contribution</label>
        </MenuItem>
      </Menu>
      <div className="householdTopDiv"></div>

      <button
        // className={styles.addNewBtn}
        data-toggle="modal"
        id="modalTransBtn"
        data-target="#modalTransaction"
        style={{ display: "none" }}
      >
        Transaction
      </button>
      <button
        // className={styles.addNewBtn}
        data-toggle="modal"
        id="modalNeedBtn"
        data-target="#modalNeed"
        style={{ display: "none" }}
      >
        Need
      </button>
      <button
        // className={styles.addNewBtn}
        data-toggle="modal"
        id="modalContributionBtn"
        data-target="#modalContribution"
        style={{ display: "none" }}
      >
        Contribution
      </button>
      <div
        className="modal fade"
        id="modalTransaction"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogWidth">
          <div className="modal-content-bg modal-content">
            <div className="modalHeading tableModalHeading">
              Transaction Data
            </div>
            <img
              src={close}
              alt=""
              className="modalCloseBtn"
              data-dismiss="modal"
              id="modalCloseBtn"
            />

            <div className="tableModalContentDiv">
              {transactionLoad === true && <div className="loader" />}
              {transactionLoad === true && (
                <div style={{ textAlign: "center" }}>
                  Loading Transaction Data
                </div>
              )}
              {transactionLoad === false && (
                <div>
                  {transactionList.length > 0 ? (
                    <MaterialTable
                      components={{
                        Toolbar: props => (
                          <div style={{ backgroundColor: "#f6f6e6" }}>
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                      columns={transactionColumns}
                      data={transactionList}
                      options={{
                        pageSize: 10,
                        searchFieldAlignment: "left",
                        searchFieldStyle: {
                          backgroundColor: "#ffffff"
                        }
                      }}
                      // title={false}
                      actions={[
                        {
                          icon: "save_alt",
                          tooltip: "Export Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#eb5757",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                               color: "#ffffff"
                            }
                          }
                          //  onClick: () => dowloadfile(),
                        },
                        {
                          icon: "refresh",
                          tooltip: "Refresh Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#41b5c2",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                               color: "#ffffff"
                            }
                          }
                          // onClick: () => fetchList(pageNo,sizePerPage),
                        }
                      ]}
                    />
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalNeed"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogWidth">
          <div className="modal-content-bg modal-content">
            <div className="modalHeading tableModalHeading">Needs Data</div>
            <img
              src={close}
              alt=""
              className="modalCloseBtn"
              data-dismiss="modal"
              id="modalCloseBtn"
            />

            <div className="modalContentDiv">
              {needLoad === true && <div className="loader" />}
              {needLoad === true && (
                <div style={{ textAlign: "center" }}>Loading Need Data</div>
              )}
              {needLoad === false && (
                <div>
                  {needList.length > 0 ? (
                    <MaterialTable
                      // other props
                      components={{
                        Toolbar: props => (
                          <div style={{ backgroundColor: "#f6f6e6" }}>
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                      columns={needColumns}
                      data={needList}
                      options={{
                        pageSize: 10,
                        searchFieldAlignment: "left",
                        searchFieldStyle: {
                          backgroundColor: "#ffffff"
                        }
                      }}
                      // title={false}
                      actions={[
                        {
                          icon: "save_alt",
                          tooltip: "Export Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#eb5757",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                               color: "#ffffff"
                            }
                          }
                          //  onClick: () => dowloadfile(),
                        },
                        {
                          icon: "refresh",
                          tooltip: "Refresh Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#41b5c2",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                               color: "#ffffff"
                            }
                          }
                          //  onClick: () => fetchList(pageNo,sizePerPage),
                        }
                      ]}
                    />
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalContribution"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogWidth">
          <div className="modal-content-bg modal-content">
            <div className="modalHeading tableModalHeading">
              Contribution Data
            </div>
            <img
              src={close}
              alt=""
              className="modalCloseBtn"
              data-dismiss="modal"
              id="modalCloseBtn"
            />

            <div className="modalContentDiv">
              {contributionLoad === true && <div className="loader" />}
              {contributionLoad === true && (
                <div style={{ textAlign: "center" }}>
                  Loading Contribution Data
                </div>
              )}
              {contributionLoad === false && (
                <div>
                  {contributionList.length > 0 ? (
                    <MaterialTable
                      components={{
                        Toolbar: props => (
                          <div style={{ backgroundColor: "#f6f6e6" }}>
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                      columns={contributionColumns}
                      data={contributionList}
                      options={{
                        pageSize: 10,
                        searchFieldAlignment: "left",
                        searchFieldStyle: {
                          backgroundColor: "#ffffff"
                        }
                      }}
                      // title={false}
                      actions={[
                        {
                          icon: "save_alt",
                          tooltip: "Export Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#eb5757",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                               color: "#ffffff"
                            }
                          }
                          //  onClick: () => dowloadfile(),
                        },
                        {
                          icon: "refresh",
                          tooltip: "Refresh Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#41b5c2",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                               color: "#ffffff"
                            }
                          }
                          // onClick: () => fetchList(pageNo,sizePerPage),
                        }
                      ]}
                    />
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <button
        data-toggle="modal"
        id="modalEmpBtn"
        data-target="#modalEmp"
        style={{ display: "none" }}
      >
        Emp
      </button>

      <div
        className="modal fade"
        id="modalEmp"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogEmpWidth">
          <div className="modal-content">
            <div className="modalHeading">Member Info</div>
            <img
              src={close}
              alt=""
              className="modalCloseBtn"
              data-dismiss="modal"
              id="modalCloseBtn"
            />

            <div className="modalContentDiv">
              {empLoad === true && <div className="loader" />}
              {empLoad === true && (
                <div style={{ textAlign: "center" }}>Loading Member Info</div>
              )}
              {empLoad === false && (
                <div className="empDiv">
                  <div className="empDataDiv">
                    <span className="empQus">Name :</span>{" "}
                    {pii == true ? (
                      <span
                        style={{
                          backgroundColor: "black",
                          color: "black",
                          cursor: "not-allowed"
                        }}
                      >
                        {empList.first_name}&nbsp; {empList.last_name}
                      </span>
                    ) : (
                      <span>
                        {empList.first_name}&nbsp; {empList.last_name}
                      </span>
                    )}
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Emp Id :</span>{" "}
                    <span>{empList.empi}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Gender :</span>{" "}
                    {pii ? (
                      <span
                        style={{
                          backgroundColor: "black",
                          color: "black",
                          cursor: "not-allowed"
                        }}
                      >
                        {empList.gender}
                      </span>
                    ) : (
                      <span>{empList.gender}</span>
                    )}
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Plan Id :</span>{" "}
                    <span>{empList.plan_id}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">State Code :</span>{" "}
                    <span>{empList.state_code}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Benefit Begin :</span>{" "}
                    <span>{empList.benefit_begin}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Benefit End :</span>{" "}
                    <span>{empList.benefit_end}</span>
                  </div>
                  <hr />
                  <div className="empDataDiv">
                    <span className="empQus">Paid Through Date:</span>{" "}
                    <span>{empList.paid_through_date}</span>
                  </div>
                  <hr />
                </div>
              )}
            </div>
            <div className="modalFooter">
              <button
                data-dismiss="modal"
                id="modalCloseBtn2"
                style={{ display: "block" }}
                className="modalFooterDoneBtn"
              >
                Done
              </button>
              <button
                data-dismiss="modal"
                id="modalCloseBtn1"
                style={{ display: "block" }}
                className="modalFooterCloseBtn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: props => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  <select
                    onChange={channelSelect}
                    className="filterByDateButton channelFilterPosition searchInput"
                    style={{ textAlign: "center" }}
                    value={selectedChannel}
                  >
                    <option value="All"> Select Channel </option>
                    {channelList.map((item, index) => (
                      <option key={index} value={item.channel_name}>
                        {item.channel_abbr}
                      </option>
                    ))}
                  </select>
                </div>
              )
            }}
            columns={columns}
            data={query =>
              new Promise((resolve, reject) => {
                fetchList(query.page + 1, query.pageSize, selectedChannel, query.search).then(
                  result => {
                    resolve({
                      data: result.data, // your data array
                      page: query.page, // current page number
                      totalCount: result.total_count // total row number
                    });
                    reject();
                  }
                );
              })
            }
            options={{
              pageSize: 10,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "#ffffff"
              }
            }}
            // title={false}
            actions={[
              {
                icon: "save_alt",
                tooltip: "Export Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#eb5757",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                     color: "#ffffff"
                  }
                }
                //  onClick: () => dowloadfile(),
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                     color: "#ffffff"
                  }
                },
                onClick: () => fetchList(pageNo, sizePerPage, selectedChannel)
              }
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default HouseHoldQuery;

