import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { setChannels, setTPRule } from '../../../../redux/actions/productActions';

const TransactionProcessingRule = () => {
  const tableRef = React.createRef();
  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState("chealth-uhf-tutela");
  const [downloadLoad, setDownloadLoad] = useState(false);

    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
        async function fetchList() {
          const request = await axios.get(
            config.regulatorPayableTransactionRule +
            selectedChannel,
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": apikey.xapikey,
                token: id_token
              }
            }
          );
          if (dataList.channels.tpRule.length === 0) {
            dispatch(setTPRule(request.data));
          }
          setAgentList(request.data);
          setLoad(false);
        }
          async function fetchChannelList() {
          const request = await axios.get(config.channelList, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          });
          dispatch(setChannels(request.data));
          setChannelList(request.data);
        }
  useEffect(() => {
    setChannelList(dataList.channels.channels);
    if (agentListCall === true) {
      setAgentListCall(false);
      if (dataList.channels.tpRule.length > 0 && agentList[0] === 'null') {
        setAgentList(dataList.channels.tpRule);
      }
      else {
        setLoad(true);
        setAgentListCall(false);

        fetchList();
      }

    }

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {
      
        fetchChannelList();
      }
    }
  }, [agentListCall, agentList, channelList]);

  const channelSelect = e => {
    e.preventDefault();
    setAgentListCall(true);
    setSelectedChannel(e.target.value);
  };


  const columns = [
    {
      field: "rule_date",
      title: "Rule Date",
     
    },
    {
      field: "affiliation_id",
      title: "Affiliation Id",
    },
    {
      field: "transaction_type",
      title: "Transaction Type"
    },
    {
      field: "plan",
      title: "Plan"
    },
    // {
    //   field: "caryn_management",
    //   title: "Caryn Management"
    // },
    // {
    //   field: "handling_fees",
    //   title: "Handling Fees"
    // },
    // {
    //   field: "hsblox",
    //   title: "HS Blox"
    // },
    // {
    //   field: "merchant_processing",
    //   title: "Merchant Processing"
    // },
    // {
    //   field: "misc",
    //   title: "Misc"
    // },
    // {
    //   field: "needs_mitigation",
    //   title: "Needs Mitigation"
    // },
    // {
    //   field: "onetime_fulfillment",
    //   title: "One Time Fullfilment"
    // },
    // {
    //   field: "payment_number_limit",
    //   title: "Payment Number Limit"
    // },
    // {
    //   field: "provider_network",
    //   title: "Provider Network"
    // },
    // {
    //   field: "sharewallet",
    //   title: "Sharewallet"
    // },
    // {
    //   field: "telemed",
    //   title: "Sharewallet"
    // },
    // {
    //   field: "third_party_referral_fee",
    //   title: "Third Party Referral Fee"
    // },
    // {
    //   field: "third_party_sales_channel",
    //   title: "Third Party Sales Channel"
    // },
    // {
    //   field: "tpa",
    //   title: "TPA"
    // },
    // {
    //   field: "uhf_monthly_dues",
    //   title: "UHF Monthly Dues"
    // },
    // {
    //   field: "uhf_operations_reserve",
    //   title: "Ops Admin"
    // }
  ];

  return (
    <ContentWrapper>
      <span className="pageheading"> Transaction Processing Rules</span>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <div>
            <MaterialTable
              // other props
              components={{
                Toolbar: props => (
                  <div style={{ backgroundColor: "#f6f6e6" }}>
                    <MTableToolbar {...props} />
                    <select
                      onChange={channelSelect}
                      className="filterByDateButton channelFilterPosition searchInput"
                      style={{ textAlign: "center" }}
                    >
                      <option value="All"> Select Channel </option>
                      {channelList.map((item, index) => (
                        <option key={index} value={item.channel_name}>
                          {item.channel_abbr}
                        </option>
                      ))}
                    </select>
                  </div>
                )
              }}
              columns={columns}
              data={agentList}
              detailPanel={rowData => {
                return (
                  <div className="detailPanel">
                    <table className="detailPanelTable">
                      <tr className="detailPaneltr">
                        <th className="detailPanelth" scope="col">
                          Caryn Management
                        </th>
                        <th className="detailPanelth" scope="col">
                          Handling Fees
                        </th>
                        <th className="detailPanelth" scope="col">
                          HS Blox
                        </th>
                        <th className="detailPanelth" scope="col">
                          Merchant Processing
                        </th>
                        <th className="detailPanelth" scope="col">
                          Misc
                        </th>
                        <th className="detailPanelth" scope="col">
                          Needs Mitigation
                        </th>
                        <th className="detailPanelth" scope="col">
                          One Time Fullfilment
                        </th>
                        <th className="detailPanelth" scope="col">
                          Payment Number Limit
                        </th>
                        <th className="detailPanelth" scope="col">
                          Telemed
                        </th>
                        <th className="detailPanelth" scope="col">
                          Provider Network
                        </th>
                        <th className="detailPanelth" scope="col">
                          Sharewallet
                        </th>
                        <th className="detailPanelth" scope="col">
                          Third Party Referral Fee
                        </th>
                        <th className="detailPanelth" scope="col">
                          Third Party Sales Channel
                        </th>
                        <th className="detailPanelth" scope="col">
                          TPA
                        </th>
                        <th className="detailPanelth" scope="col">
                          UHF Monthly Dues
                        </th>
                        <th className="detailPanelth" scope="col">
                          Ops Admin
                        </th>
                      </tr>
                      <tr>
                        <td className="detailPaneltd">
                          {rowData.caryn_management}
                        </td>
                        <td className="detailPaneltd">
                          {rowData.handling_fees}
                        </td>
                        <td className="detailPaneltd">{rowData.hsblox}</td>
                        <td className="detailPaneltd">
                          {rowData.merchant_processing}
                        </td>
                        <td className="detailPaneltd">{rowData.misc}</td>
                        <td className="detailPaneltd">
                          {rowData.needs_mitigation}
                        </td>
                        <td className="detailPaneltd">
                          {rowData.onetime_fulfillment}
                        </td>
                        <td className="detailPaneltd">
                          {rowData.payment_number_limit}
                        </td>
                        <td className="detailPaneltd">
                          {rowData.provider_network}
                        </td>
                        <td className="detailPaneltd">{rowData.telemed}</td>
                        <td className="detailPaneltd">{rowData.sharewallet}</td>
                        <td className="detailPaneltd">
                          {rowData.third_party_referral_fee}
                        </td>
                        <td className="detailPaneltd">
                          {rowData.third_party_sales_channel}
                        </td>
                        <td className="detailPaneltd">{rowData.tpa}</td>
                        <td className="detailPaneltd">
                          {rowData.uhf_monthly_dues}
                        </td>
                        <td className="detailPaneltd">
                          {rowData.uhf_operations_reserve}
                        </td>
                      </tr>
                    </table>
                  </div>
                );
              }}
              options={{
                pageSize: 10,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                  backgroundColor: "#ffffff"
                }
              }}
              // title={false}
              actions={[
                {
                  icon: "save_alt",
                  tooltip: "Export Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#eb5757",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                       color: "#ffffff"
                    }
                  },
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange()
                },
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#41b5c2",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                       color: "#ffffff"
                    }
                  },
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange()
                }
              ]}
            />
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default TransactionProcessingRule;

