import {ActionTypes} from '../contants/action-types';

export const setChannels = (channels) => {
    return {
      type: ActionTypes.SET_CHANNELS,
      payload: channels
    };
}
export const setHouseholdReport= (householdReport) => {
  return {
    type: ActionTypes.SET_CHANNELS,
    payload: householdReport
  };
}
export const setFileProcess = fileprocess => {
  return {
    type: ActionTypes.SET_FILE_PROCESS,
    payload: fileprocess
  };
};

export const setSharewalletBalance = (sharewallet) => {
  return {
    type: ActionTypes.SET_SHAREWALLET_BALANCE,
    payload: sharewallet
  };
};

export const setAdminReserveMontly = (adminReserveMonth) => {
  return {
    type: ActionTypes.SET_ADMINRESERVE_MONTH,
    payload: adminReserveMonth
  };
};

export const setAdminReserveWeekly = (adminReserveWeek) => {
  return {
    type: ActionTypes.SET_ADMINRESERVE_WEEK,
    payload: adminReserveWeek
  };
};

export const setYTD = (ytd) => {
  return {
    type: ActionTypes.SET_YTD,
    payload: ytd
  };
};

export const setYTDMonth = (ytdMonth) => {
  return {
    type: ActionTypes.SET_YTD_MONTH,
    payload: ytdMonth
  };
};

export const setYTDWeek = (ytdWeek) => {
  return {
    type: ActionTypes.SET_YTD_WEEK,
    payload: ytdWeek
  };
};

export const setAggregateReportByChannel = (aggregateReportByChannel) => {
  return {
    type: ActionTypes.SET_AGGREGATE_REPORT,
    payload: aggregateReportByChannel
  };
};

export const setTPRule = (tpRule) => {
  return {
    type: ActionTypes.SET_TP_RULE,
    payload: tpRule
  };
};

export const setShareplusSummary = (summary) => {
  return {
    type: ActionTypes.SET_SUMMARY,
    payload: summary
  };
};

export const setNeeds = (needs) => {
  return {
    type: ActionTypes.SET_NEEDS,
    payload: needs
  };
};

export const setNeedsRequest = (needsRequest) => {
  return {
    type: ActionTypes.SET_NEEDS_REQUEST,
    payload: needsRequest
  };
};

export const setNeedsAggregateMontly = (needsAggregateMonthly) => {
  return {
    type: ActionTypes.SET_NEEDS_AGGREGATE_MONTH,
    payload: needsAggregateMonthly
  };
};

export const setHouseholdMontly = (householdMonthly) => {
  return {
    type: ActionTypes.SET_HOUSEHOLD_MONTH,
    payload: householdMonthly
  };
};

export const setHouseholdWeekly = (householdWeekly) => {
  return {
    type: ActionTypes.SET_HOUSEHOLD_WEEK,
    payload: householdWeekly
  };
};


export const setMemberMonthly = (memberMonthly) => {
  return {
    type: ActionTypes.SET_MEMBER_MONTH,
    payload: memberMonthly
  };
};
export const setMemberWeekly = (memberWeekly) => {
  return {
    type: ActionTypes.SET_MEMBER_WEEK,
    payload: memberWeekly
  };
};

export const setHouseholdQuery = (householdQuery) => {
  return {
    type: ActionTypes.SET_HOUSEHOLD_QUERY,
    payload: householdQuery
  };
};
export const setHouseholdPastDue = (householdPastDue) => {
  return {
    type: ActionTypes.SET_HOUSEHOLD_PASTDUE,
    payload: householdPastDue
  };
};

export const setAgencyCommission = (agencyCommission) => {
  return {
    type: ActionTypes.SET_AGENCY_COMMISSION,
    payload: agencyCommission
  };
};

export const setAgentSale = (agentSale) => {
  return {
    type: ActionTypes.SET_AGENT_SALE,
    payload: agentSale
  };
};

export const setAgentCommission = (agentCommission) => {
  return {
    type: ActionTypes.SET_AGENT_COMMISSION,
    payload: agentCommission
  };
};