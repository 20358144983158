import React from "react";
import ContentWrapper from "../../Common/ContentWrapper";

const RegulatorHomePage = () => {
  return (
    <ContentWrapper>
      <h2>RegulatorHomePage Page</h2>
    </ContentWrapper>
  );
};

export default RegulatorHomePage;
