import { ActionTypes } from "../contants/action-types";

const initialState = {
  channels:[],
  fileprocess: [],
  householdPastDue:[],
  householdReport:[],
  sharewallet: [],
  adminReserveMonth: [],
  adminReserveWeek: [],
  ytd: [],
  ytdMonth: [],
  ytdWeek: [],
  aggregateReportByChannel: [],
  tpRule: [],
  summary: [],
  needs: [],
  needsRequest: [],
  needsAggregateMonthly: [],
  householdMonthly: [],
  householdWeekly: [],
  memberMonthly: [],
  memberWeekly: [],
  householdQuery: [],
  agencyCommission: [],
  agentSale: [],
  agentCommission: []
};

export const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_CHANNELS:
      return { ...state, channels: payload };
    case ActionTypes.SET_HOUSEHOLDREPORT:
        return { ...state, householdReport: payload };
    case ActionTypes.SET_FILE_PROCESS:
      return { ...state, fileprocess: payload };
      
    case ActionTypes.SET_SHAREWALLET_BALANCE:
      return { ...state, sharewallet: payload };
    case ActionTypes.SET_ADMINRESERVE_MONTH:
      return { ...state, adminReserveMonth: payload };
    case ActionTypes.SET_ADMINRESERVE_WEEK:
      return { ...state, adminReserveWeek: payload };

    case ActionTypes.SET_YTD:
      return { ...state, ytd: payload };
    case ActionTypes.SET_YTD_MONTH:
      return { ...state, ytdMonth: payload };
    case ActionTypes.SET_YTD_WEEK:
      return { ...state, ytdWeek: payload };

    case ActionTypes.SET_AGGREGATE_REPORT:
      return { ...state, aggregateReportByChannel: payload };
    case ActionTypes.SET_TP_RULE:
      return { ...state, tpRule: payload };
    case ActionTypes.SET_SUMMARY:
      return { ...state, summary: payload };
    case ActionTypes.SET_NEEDS:
      return { ...state, needs: payload };
    case ActionTypes.SET_NEEDS_REQUEST:
      return { ...state, needsRequest: payload };
    case ActionTypes.SET_NEEDS_AGGREGATE_MONTH:
      return { ...state, needsAggregateMonthly: payload };

    case ActionTypes.SET_HOUSEHOLD_MONTH:
      return { ...state, householdMonthly: payload };
    case ActionTypes.SET_HOUSEHOLD_WEEK:
        return { ...state, householdWeekly: payload };
    case ActionTypes.SET_MEMBER_MONTH:
      return { ...state, memberMonthly: payload };   
    case ActionTypes.SET_MEMBER_WEEK:
        return { ...state, memberWeekly: payload };

    case ActionTypes.SET_HOUSEHOLD_QUERY:
      return { ...state, householdQuery: payload };

    case ActionTypes.SET_AGENCY_COMMISSION:
      return { ...state, agencyCommission: payload };
    case ActionTypes.SET_AGENT_SALE:
      return { ...state, agentSale: payload };
    case ActionTypes.SET_AGENT_COMMISSION:
      return { ...state, agentCommission: payload };
    default:
      return state;
  }
};

