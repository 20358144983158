import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { setNeedsRequest } from '../../../../redux/actions/productActions';
import { ExportToCsv } from "export-to-csv";

const NeedsRequests = () => {
  const tableRef = React.createRef();
  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [downloadLoad, setDownloadLoad] = useState(false);

    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "NeedsRequest"
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      config.regulatorNeedsRequests,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    dispatch(setNeedsRequest(request.data));
    setAgentList(request.data);
    setLoad(false);
  }
  async function downloadList() {
  setDownloadLoad(true);
    const request = await axios.get(
      config.regulatorNeedsRequests,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
    setDownloadLoad(false);
  }
  useEffect(() => {
    if (agentListCall === true) {
      if (dataList.channels.needsRequest.length > 0) { 
        setAgentList(dataList.channels.needsRequest);
      } else {
        setLoad(true);
        setAgentListCall(false);

        fetchList();
      }
      
    }
  }, [
      agentListCall,
      agentList
    ]);
  

  const GetTotalPercentage = (cell) => {
    return (
      <div>
        {cell.needs_percentage}%
      </div>
    );
  };

  const GetChargedAmount = (cell) => {
    var amount = cell.charged_amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    return (
      <div>
        {amount}
      </div>
    );
  };

  const GetRepricingAmount = (cell) => {
    var amount = cell.repricing_amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    return (
      <div>
        {amount}
      </div>
    );
  };

  const GetIneligibleAmount = (cell) => {
    var amount = cell.ineligible_amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    return (
      <div>
        {amount}
      </div>
    );
  };

  const GetNsa = (cell) => {
    var amount = cell.nsa.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    return (
      <div>
        {amount}
      </div>
    );
  };

  const GetConsultationFee = (cell) => {
    var amount = cell.consultation_fee.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    return (
      <div>
        {amount}
      </div>
    );
  };

  const GetSharingPercentage = (cell) => {
    var amount = cell.sharing_percentage.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    return (
      <div>
        {amount}
      </div>
    );
  };

  const GetPaidAmount = (cell) => {
    var amount = cell.paid_amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    return (
      <div>
        {amount}
      </div>
    );
  };


  const columns = [
    {
      field: "claim_status",
      title: "Claim Status",
      cellStyle: {
        width: '300px',
      }
    
    },
    {
      field: "needs_count",
      title: "Needs Requests"
    },
    {
      field: "needs_percentage",
      title: "% of Total Needs",
      render: rowData => GetTotalPercentage(rowData)
    },
    {
      field: "charged_amount",
      title: "Original Charge",
      render: rowData => GetChargedAmount(rowData)

    },
    {
      field: "repricing_amount",
      title: "Repricing Discount",
      render: rowData => GetChargedAmount(rowData)
    },
    {
      field: "ineligible_amount",
      title: "Ineligible Amount",
      render: rowData => GetIneligibleAmount(rowData)
    },
    {
      field: "nsa",
      title: "Applied to NSA",
      render: rowData => GetNsa(rowData)
    },
    {
      field: "consultation_fee",
      title: "Applied to Consultation fee",
      render: rowData => GetConsultationFee(rowData)
    },
    {
      field: "sharing_percentage",
      title: "Applied to Sharing Percentage",
      render: rowData => GetSharingPercentage(rowData)
    },
    {
      field: "paid_amount",
      title: "Paid",
      render: rowData => GetPaidAmount(rowData)
    }
  ];



  return (
    <ContentWrapper>
      <span className="pageheading"> Needs Requests</span>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: props => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  {downloadLoad === true && <div className="loaderDwnld" />}
                  {downloadLoad === true && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        right: "50px"
                      }}
                    >
                      downloading
                    </div>
                  )}
                  <div style={{ display: "inline-block" }}></div>
                </div>
              )
            }}
            columns={columns}
            data={agentList}
            options={{
              pageSize: 10,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "#ffffff"
              }
            }}
            // title={false}
            actions={[
              {
                icon: "save_alt",
                tooltip: "Export Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#eb5757",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                     color: "#ffffff"
                  }
                },
                onClick: () => downloadList()
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                     color: "#ffffff"
                  }
                },
                onClick: () => fetchList()
              }
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default NeedsRequests;

